import { gql } from 'graphql-request';

// Big query that all pages send in the layout.
// It's better to reuse the same query everywhere to benefit from the cache.
export const websiteQuery2 = gql`
  query WebsiteQuery {
    website {
      id
      name
      url
      color
      publicKey
      languages
      disableShareDiscussion
      disableMailNotif
      subscribeByTokenVip
      owner {
        id
      }
      administrators {
        id
      }
      moderators {
        id
      }
      labels {
        owner
        moderator
      }
      custom {
        image
        logo {
          image {
            id
            filename
          }
          imageActive
          text
          textActive
        }
        optInsActive
        optInsTrigger {
          active
          label
        }
        faviconArrayHtml
      }
      cgu {
        inputFrontActive
        inputFrontTxt
      }
      graphdebate {
        meta {
          common {
            title
            description
          }
          homepage {
            title
            description
          }
          topic {
            title
            description
            uri
          }
          category {
            title
            description
            uri
          }
          profile {
            title
            description
            uri
          }
          search {
            title
            description
            uri
          }
        }
        sso {
          active
          externalUrlLogin
          manageAvatar
          managePseudo
        }
        # dsa
        baseUrl
        countPerPage
        countPerListPage
        loadingDepth
        lazyLoad
        embededFiles
        embededExternalLink
        edition {
          upload
          link
          formatting
          emoticons
        }
        hideTimestamp
        votesDiscreet
        firstBlocHomepage
        latestTopicsSortOrder
        sort
        group
        languages
        websocket {
          notification
        }
      }
      login {
        email
        twitter
        google
        facebook
        linkedin
        order
      }
      moderationContent {
        active
        permanent
        temporary
      }
      pushWeb {
        oneSignal {
          active
          appId
          apiKey
        }
      }
    }
  }
`;
